<template>
  <a-layout id="ecIndex">
    <ec-sider />
    <a-layout class="ec-body">
      <ec-header />
      <a-layout class="ec-content-wrapper">
        <div class="ec-content-wrapper-inner">
          <ec-breadcrumb />
          <a-layout-content class="ec-content">
            <transition name="slide-fade">
              <router-view />
            </transition>
          </a-layout-content>
        </div>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import EcSider from "./components/Sider.vue";
import EcHeader from "./components/Header.vue";
import EcBreadcrumb from "./components/Breadcrumb.vue";

export default {
  components: {
    EcSider,
    EcHeader,
    EcBreadcrumb
  },
  data() {
    return {
      collapsed: false
    };
  }
};
</script>

<style lang="less">
#ecIndex {
  height: 100%;

  .ec-body {
    height: 100%;
    display: block;
    .clear-fix();
    overflow-y: hidden;
    .ec-content-wrapper {
      padding: 0;
      height: calc(100% - @ec-header);
      float: left;
      width: 100%;
      overflow: auto;
      background: #fff;

      &-inner {
        height: 100%;
        padding: 0 20px 20px;
      }

      .ec-content {
        height: calc(100% - 46px);
        margin: 0;
        overflow-y: auto;
      }
    }
  }
}
</style>
