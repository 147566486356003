<template>
  <div class="industry-selector" :class="lang">
    <div class="industry-selector-top">
      <div class="industry-selector-tips">
        <span class="industry-selector-tips-icon"></span>
        {{ $t("home.card5.tips1") }}
        <strong>5</strong>
        {{ $t("home.card5.tips2") }}
      </div>
      <a-button
        type="primary"
        @click="onDefault"
        size="small"
        ghost
        class="industry-selector-btn"
      >
        {{ $t("home.card5.default") }}
      </a-button>
    </div>
    <div class="industry-selector-content">
      <a-row
        class="industry-selector-row"
        v-for="item in industryList"
        :key="item.title"
      >
        <a-col class="industry-selector-group"> {{ item.alter }}： </a-col>
        <a-col class="industry-selector-checkbox">
          <a-checkbox
            v-for="it in item.data"
            :key="it.name"
            :checked="selected.includes(it.name)"
            @change="e => onChecked(e.target.checked, it)"
          >
            {{ it.name }}
          </a-checkbox>
        </a-col>
      </a-row>
    </div>
    <div class="industry-selector-bot">
      {{ $t("home.card5.link") }}&nbsp;&nbsp;
      <a href="javascript: void(0);" @click="onMore">{{
        $t("home.card5.more")
      }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DEFAULT_INDUSTRY, DEFAULT_INDUSTRY_EN } from "@/store/modules/user";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins";

export default {
  name: "IndustrySelector",
  props: {},
  mixins: [langMixin],
  data() {
    return {
      selected: []
    };
  },
  computed: {
    ...mapState({
      industryList: state => state.user.industry,
    })
  },
  methods: {
    onDefault() {
      this.selected = [...DEFAULT_INDUSTRY];
      if (this.isEnglish) {
        this.selected = [...DEFAULT_INDUSTRY_EN];
      }
    },
    onChecked(checked, item) {
      let selected = [...this.selected];
      // 勾选事件
      if (checked) {
        if (!selected.includes(item.name)) {
          selected.push(item.name);
        }
        if (selected.length > 5) {
          selected = selected.slice(selected.length - 5);
        }
      }
      // 取消勾选
      else {
        if (selected.length < 2) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("home.card5.lastOne")
          });
          return;
        }
        const idx = selected.indexOf(item.name);
        if (idx > -1) {
          selected.splice(idx, 1);
        }
      }
      this.selected = selected;
    },
    onMore() {
      this.$emit("cancel");
      this.$emit("smartSalary");
    }
  },
  mounted() {
    this.selected = this.$store.state.user.selectedIndustry.map(it => {
      return it.name;
    });
  }
};
</script>

<style lang="less" scoped>
.industry-selector {
  &-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 0;
    gap: 20px;
  }
  &-bot {
    padding-top: 20px;
  }
  &-tips {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    strong {
      color: #f03738;
    }

    &-icon {
      display: block;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icon-tips.png") no-repeat center / cover;
    }
  }
  &-btn {
    border-radius: 4px;
    font-size: 14px;
  }
  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  &-group {
    flex-shrink: 0;
    flex-grow: 0;
    width: 100px;
    flex-basis: 100px;
    text-align: right;
  }
  &-checkbox {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .ant-checkbox-wrapper {
      margin: 0;
    }
  }

  &.en {
    .industry-selector-group {
      width: 160px;
      flex-basis: 160px;
    }
    .industry-selector-checkbox {
      flex-wrap: wrap;
    }
  }
}
</style>
