<template>
  <a-modal
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    :maskClosable="false"
    title=""
    width="712px"
    destroyOnClose
    :okText="$t('home.card5.auth.confirm')"
    wrapClassName="smartsalary-modal"
  >
    <Index ref="indexRef" />
  </a-modal>
</template>

<script>
import Index from "./Index.vue";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";
import { getSmartSalary } from "@/pages/home/api";

export default {
  name: "SmartsalaryModal",
  components: { Index },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onOk() {
      if (this.loading) return;
      this.loading = true;
      const data = {};
      const idList = ["companyName", "email", "name", "phone"];
      const selected = this.$refs.indexRef.selected || [idList[0], idList[1]];
      for (const it of idList) {
        data[it] = selected.includes(it);
      }
      const res = await getSmartSalary(data);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      window.open(res.data);
      this.loading = false;
      this.onCancel();
    },
    onCancel() {
      this.$emit("update:visible", false);
    },
  },
  mounted() {},
}
</script>

<style lang="less">
.smartsalary-modal {
  .ant-modal-body {
    padding: 8px 44px;
  }
  .smartsalary-checkbox {
    .ant-checkbox-disabled + span {
      color: #293B58;
    }
  }
}
</style>
