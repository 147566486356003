var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-breadcrumb',{staticClass:"ec-breadcrumb",attrs:{"routes":_vm.breadcrumbList},scopedSlots:_vm._u([{key:"itemRender",fn:function({ route, routes }){return [(route.href)?[(routes.indexOf(route) !== routes.length - 1)?[(route.icon)?_c('a-icon',{attrs:{"type":route.icon}}):_vm._e(),_c('router-link',{attrs:{"to":route.href}},[_vm._v(_vm._s(_vm.handleName(route)))])]:[(
              _vm.$route.fullPath !== route.href &&
                _vm.$route.fullPath.includes(route.href)
            )?[_c('router-link',{class:[
                routes.indexOf(route) !== routes.length - 1
                  ? 'router-link'
                  : 'router-active'
              ],attrs:{"to":route.href}},[_vm._v(" "+_vm._s(_vm.handleName(route))+" ")]),_c('span',{staticClass:"router-link"},[_c('span',{staticClass:"router-separator"},[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.handleName(route))+" "+_vm._s(_vm.$route.fullPath.endsWith("/add") ? _vm.$t("button.add") : _vm.$t("button.edit"))+" ")])]:_c('span',[_vm._v(_vm._s(_vm.handleName(route)))])]]:_c('span',[(route.icon)?_c('a-icon',{attrs:{"type":route.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.handleName(route))+" ")],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }