<template>
  <div class="smartsalary">
    <div class="smartsalary-top">
      <img src="~@/assets/images/accreditPic.png" alt="" class="smartsalary-icon" />
      <h5 class="smartsalary-title">
        {{ $t("home.card5.auth.title") }}
      </h5>
    </div>
    <div class="smartsalary-content">
      <div class="smartsalary-tips">
        {{ $t("home.card5.auth.note") }}
      </div>
      <a-row class="smartsalary-selector">
        <a-col
          class="smartsalary-item"
          v-for="it in list"
          :key="it.value"
        >
          <a-checkbox
            class="smartsalary-checkbox"
            :checked="selected.includes(it.value)"
            @change="(e) => onChecked(e.target.checked, it)"
            :disabled="it.disabled"
          >
            <span class="smartsalary-checkbox-text">{{ it.label }}</span>
            <span class="smartsalary-checkbox-required" v-if="it.required">
              {{ $t("home.card5.auth.required") }}
            </span>
            <span class="smartsalary-checkbox-suffix" v-if="it.suffix">
              {{ it.suffix }}
            </span>
          </a-checkbox>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Smartsalary",
  props: {},
  data() {
    return {
      selected: ["companyName", "email", "name", "phone"],
    };
  },
  computed: {
    list() {
      return [
        {
          value: "companyName",
          label: this.$t("home.card5.auth.company"),
          disabled: true,
          required: true,
        },
        {
          value: "email",
          label: this.$t("home.card5.auth.email"),
          disabled: true,
          required: true,
          suffix: `（${this.$store.state.user.info.emailAddress}）`,
        },
        {
          value: "name",
          label: this.$t("home.card5.auth.name"),
        },
        {
          value: "phone",
          label: this.$t("home.card5.auth.phone"),
        },
      ];
    },
  },
  methods: {
    onChecked(checked, item) {
      let selected = [...this.selected];
      // 勾选事件
      if (checked) {
        if (!selected.includes(item.value)) {
          selected.push(item.value);
        }
      }
      // 取消勾选
      else {
        const idx = selected.indexOf(item.value);
        if (idx > -1) {
          selected.splice(idx, 1);
        }
      }
      this.selected = selected;
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.smartsalary {
  &-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  &-icon {
    display: block;
    width: 120px;
    height: 106px;
  }
  &-title {
    font-size: 20px;
    margin: 0;
    font-weight: 500;
    color: #293B58;
  }
  &-content {
    margin-top: 18px;
  }
  &-tips {
    font-size: 16px;
    font-weight: 400;
    color: #293B58;
  }
  &-selector {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  &-item {
    font-size: 16px;
    font-weight: 400;
    color: #293B58;
  }
  &-checkbox {
    &-text {}
    &-required {
      color: #F03738;
    }
    &-suffix {}
  }
}
</style>
