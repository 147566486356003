<template>
  <a-modal
    :maskClosable="false"
    :visible="visible"
    :title="title"
    @ok="ok"
    @cancel="cancel"
    class="audit-modal"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      ref="form"
      :colon="false"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 16 }"
      labelAlign="right"
    >
      <a-form-model-item :label="$t('audit.cols.publisher')">
        {{ item.title }}
      </a-form-model-item>
      <template v-if="type === '1'">
        <a-form-model-item
          :label="$t('audit.modal.awardNum', { unit: platformConf.moneyName })"
          prop="points"
        >
          <a-input-number
            :placeholder="
              $t('audit.modal.awardNum', { unit: platformConf.moneyName })
            "
            v-model="form.points"
            :min="0"
            :precision="2"
          />
          <div>
            {{
              $t("audit.modal.tip", {
                value: pointsBalance,
                unit: platformConf.moneyName
              })
            }}
          </div>
        </a-form-model-item>
        <a-form-model-item :label="$t('audit.modal.payPwd')" prop="password">
          <a-input
            type="password"
            :placeholder="$t('audit.modal.payPwd')"
            v-model="form.password"
          />
        </a-form-model-item>
      </template>
      <template v-if="type === '2'">
        <a-form-model-item :label="$t('audit.cols.reason')" prop="remark">
          <a-textarea
            :placeholder="$t('audit.cols.reason')"
            :autosize="{ minRows: 4, maxRows: 8 }"
            v-model="form.remark"
          />
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { PRICE_RATE_BACK } from "@/config";
import BigNumber from "bignumber.js";

export default {
  name: "AuditModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "1"
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    title() {
      let title = this.$t("button.audit");
      switch (this.type) {
        case "1":
          title += this.$t("button.pass");
          break;
        case "2":
          title += this.$t("button.refuse");
          break;
      }
      return title;
    },
    ...mapGetters("user", ["pointsBalance"]),
    ...mapState({
      platformConf: state => state.user.platformConf
    }),
    rules() {
      return {
        password: [
          {
            required: true,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("audit.modal.payPwd")
          }
        ],
        remark: [
          {
            required: true,
            message: this.$t("input") + this.$t("audit.cols.reason")
          }
        ],
        points: [
          {
            required: true,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("audit.modal.awardNum", {
                unit: this.platformConf.moneyName
              })
          }
        ]
      };
    }
  },
  data() {
    return {
      form: {
        password: "",
        points: "",
        remark: ""
      }
    };
  },
  methods: {
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.point = BigNumber(this.form.points)
            .times(PRICE_RATE_BACK)
            .toNumber();
          this.$emit("getForm", this.form);
        }
      });
    },
    cancel() {
      this.$refs.form.resetFields();
      this.form = {};
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less" scoped></style>
