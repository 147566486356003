<template>
  <a-layout-sider width="200" class="ec-sider-wrapper">
    <div class="logo">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <a-menu
      class="home-sider-menu-wrapper"
      mode="inline"
      :selected-keys="[menuActive]"
      :open-keys="menuOpenKeys"
      :style="{ height: '100%', borderRight: 0 }"
      @click="onMenu"
      @openChange="onOpen"
    >
      <template v-for="item in menuTree">
        <sub-menu
          v-if="item.children && item.children.length"
          :key="item.permissionId"
          :menu="item"
        />
        <a-menu-item
          :key="item.permissionId"
          v-if="!(item.children && item.children.length) && !item.hideFlag"
        >
          <a-icon :type="item.icon" v-if="item.icon" />
          <span class="title-text">{{ $t(item.name) }}</span>
        </a-menu-item>
      </template>
    </a-menu>
    <div class="use-book">
      <a
        href="javascript: void(0);"
        @click="
          onFileDownload('/template/思博众合操作手册20240415.pdf')
        "
        v-if="isHrmany"
      >
        {{ $t("home.useBook") }}
      </a>
      <a
        href="javascript: void(0);"
        @click="
          onFileDownload('/template/易查背调操作手册20240415.pdf')
        "
        v-else
      >
        {{ $t("home.useBook") }}
      </a>
    </div>
  </a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SubMenu from "./Menu.vue";
import { clearCookies } from "@/config";

export default {
  components: {
    SubMenu
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      menuActive: state => state.home.menuActive,
      menuOpenKeys: state => state.home.menuOpenKeys
    }),
    ...mapGetters({
      menuTree: "home/menuTree"
    }),
    isHrmany() {
      return (
        process.env.VUE_APP_CLIENT === "hrmany" ||
        /hrmany\.com$/.test(window.location.hostname)
      );
    }
  },
  methods: {
    onOpen(openKeys) {
      this.$store.commit("home/onMenuOpenKeys", {
        keys: openKeys
      });
    },
    onMenu(opt) {
      const { key } = opt;
      this.$store.commit("home/onMenu", {
        value: key,
        success: route => {
          this.$router
            .push({
              path: route.href
            })
            .catch(() => {});
        }
      });
    },
    onFileDownload(path) {
      this.$confirm({
        title: this.$t("home.downloadUseBook"),
        onOk: () => {
          const a = document.createElement("a");
          a.href = path;
          a.setAttribute("download", path.replace(/\/template\//, ""));
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        onCancel: () => {}
      });
    }
  }
};
</script>

<style lang="less" scoped>
.ec-sider-wrapper {
  background-color: @menu-color;
  float: left;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: none;
  }
  /deep/.ant-layout-sider-children {
    height: unset;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }
  .logo {
    width: 100%;
    height: 100px;
    flex-basis: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: @menu-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 10px;

    img {
      width: 100%;
    }
  }
  .home-sider-menu-wrapper {
    flex: 1;
  }
  .use-book {
    height: 50px;
    line-height: 50px;
    flex-basis: 50px;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 18px;
    text-align: center;
  }
}
/deep/.ant-menu {
  background: @menu-color;
}
</style>
