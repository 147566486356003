<template>
  <div class="audit-wrap">
    <a-tabs v-model:activeKey="activeTab" @change="onChangeTab">
      <a-tab-pane :tab="tab.tab" v-for="tab in tabList" :key="tab.key">
        <ECTable
          :columns="columns"
          :data-source="tab.dataList"
          :loading="tab.loading"
          :panigation="{
            current: tab.current,
            pageSize: tab.pageSize,
            total: tab.total
          }"
          key="id"
          rowKey="id"
          @change="onPageChange"
        >
          <template slot="index" slot-scope="text, record, index">
            {{ (tab.current - 1) * tab.pageSize + index + 1 }}
          </template>
          <template slot="content" slot-scope="text, record">
            <!--            <a-popover>-->
            <!--              <div slot="content">-->
            <div class="two-hidden">{{ record.remark }}</div>
            <!--                <div v-if="record.pictureUrl" class="content-img-wrap">-->
            <!--                  <img v-for="i in record.pictureUrl.split(',')" :key="i" :src="i" alt="" class="content-img">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div>{{ record.remark }}</div>-->
            <!--            </a-popover>-->
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="table-operations">
              <a-button
                type="link"
                class="table-btn view"
                @click="onView(record)"
              >
                <a-icon type="eye" />
                {{ $t("button.view") }}
              </a-button>
              <template v-if="isAuth && activeTab === '1'">
                <a-button
                  type="link"
                  class="table-btn"
                  @click="onAudit(record, '1')"
                >
                  <a-icon type="check-circle" />
                  {{ $t("button.pass") }}
                </a-button>
                <a-button
                  type="link"
                  class="table-btn danger"
                  @click="onAudit(record, '2')"
                >
                  <a-icon type="close-circle" />
                  {{ $t("button.refuse") }}
                </a-button>
              </template>
            </div>
          </template>
        </ECTable>
      </a-tab-pane>
    </a-tabs>
    <template v-if="isShowModal">
      <AuditModal
        :visible.sync="isShowModal"
        :type="type"
        :item="item"
        @getForm="getForm"
      />
    </template>
    <div v-if="isViewModal">
      <a-modal
        v-model="isViewModal"
        :title="$t('button.view') + $t('audit.cols.content')"
        @ok="isViewModal = false"
        class="view-modal"
      >
        <div class="view-modal_content">
          <div><span class="bold">{{ $t("audit.cols.publisher") }}：</span>{{ viewItem.title }}</div>
          <div><span class="bold">{{ $t("audit.cols.content") }}：</span>{{ viewItem.remark }}</div>
          <div v-if="viewItem.pictureUrl" class="content-img-wrap">
            <img
              v-for="i in viewItem.pictureUrl.split(',')"
              :key="i"
              :src="i"
              alt=""
              class="content-img"
            />
          </div>
          <div v-if="viewItem.rejectedReason">
            <span class="bold">{{ $t("audit.cols.reason") }}：</span> {{ viewItem.rejectedReason }}
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import ECTable from "@/components/ECTable";
import {
  getProposeAuth,
  getProposePage,
  passPropose,
  rejectPropose
} from "@/pages/auditTricks/api";
import { FETCH_CODE } from "@/config";
import AuditModal from "@/pages/auditTricks/Modal.vue";
import {showMsg} from "@/utils";

export default {
  components: { AuditModal, ECTable },
  data() {
    return {
      activeTab: "1",
      columns: [
        {
          dataIndex: "index",
          title: this.$t("audit.cols.index"),
          align: "center",
          scopedSlots: { customRender: "index" }
        },
        {
          dataIndex: "title",
          title: this.$t("audit.cols.publisher"),
          align: "center"
        },
        {
          dataIndex: "content",
          title: this.$t("audit.cols.content"),
          align: "center",
          width: 200,
          scopedSlots: { customRender: "content" }
        },
        {
          dataIndex: "createTime",
          title: this.$t("audit.cols.time"),
          align: "center"
        },
        {
          dataIndex: "stateName",
          title: this.$t("audit.cols.status"),
          align: "center"
        },
        {
          key: "action",
          dataIndex: "action",
          align: "center",
          title: this.$t("action"),
          scopedSlots: { customRender: "action" }
        }
      ],
      dataList: [],
      tabList: [
        {
          key: "ALL",
          tab: this.$t("productManagement.product.list.tabAll"),
          dataList: [],
          loading: false,
          state: "",
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false
        },
        {
          key: "1",
          tab: this.$t("audit.pending"),
          dataList: [],
          loading: false,
          state: "un_approved",
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false
        },
        {
          key: "2",
          tab: this.$t("audit.pass"),
          dataList: [],
          loading: false,
          state: "passed",
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false
        },
        {
          key: "3",
          tab: this.$t("audit.rejected"),
          dataList: [],
          loading: false,
          state: "rejected",
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false
        }
      ],
      isShowModal: false,
      item: {},
      isAuth: false,
      type: "1",
      isViewModal: false,
      viewItem: {}
    };
  },
  methods: {
    onChangeTab() {
      console.log(this.activeTab);
      if (this.activeTab === "3") {
        const index = this.columns.findIndex(i => i.dataIndex === "createTime");
        if (index !== -1) {
          this.columns.splice(index, 0, {
            dataIndex: "rejectedReason",
            title: this.$t("audit.cols.reason"),
            align: "center",
            width: 150,
            ellipsis: true
          });
        }
      } else {
        const ind = this.columns.findIndex(
          i => i.dataIndex === "rejectedReason"
        );
        if (ind !== -1) {
          this.columns.splice(ind, 1);
        }
      }
      // const actionInd = this.columns.findIndex(i => i.dataIndex === "action");
      // if (this.activeTab !== "1") {
      //   if (actionInd !== -1) {
      //     this.columns.splice(actionInd, 1);
      //   }
      // } else {
      //   if (this.isAuth) {
      //     this.columns.push({
      //       key: "action",
      //       dataIndex: "action",
      //       align: "center",
      //       title: this.$t("action"),
      //       scopedSlots: { customRender: "action" }
      //     });
      //   }
      // }
      // console.log(this.columns);
      this.getList();
      // this.activeTab = tab
    },
    async getList() {
      const index = this.tabList.findIndex(i => i.key === this.activeTab);
      if (index === -1) {
        return;
      }
      const { current, pageSize, state } = this.tabList[index];
      this.tabList[index].loading = true;
      const params = {
        pageNum: current,
        pageSize,
        state
      };
      const res = await getProposePage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
        this.tabList[index].loading = false;
        return;
      }
      this.tabList[index].dataList = res.data?.records || [];
      this.tabList[index].current = res.data.current;
      this.tabList[index].pageSize = res.data.size;
      this.tabList[index].total = res.data.total;
      this.tabList[index].loading = false;
    },
    onAudit(item, type) {
      this.item = item;
      this.type = type;
      this.isShowModal = true;
    },
    onView(item) {
      this.viewItem = item;
      this.isViewModal = true;
    },
    onPageChange(page) {
      const index = this.tabList.findIndex(i => i.key === this.activeTab);
      if (index === -1) {
        return;
      }
      this.tabList[index].current = page.current;
      this.tabList[index].pageSize = page.pageSize;
      this.getList();
    },
    async getForm(form) {
      let res = {};
      if (this.type === "1") {
        const params = {
          id: this.item.id,
          points: form.point,
          payPassword: form.password
        };
        res = await passPropose(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        this.isShowModal = false;
        showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.audit") })
        this.getList();
        // 刷新余额
        this.$store.dispatch("user/getUserInfo");
        return;
      }
      if (this.type === "2") {
        res = await rejectPropose({ id: this.item.id, remark: form.remark });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        this.isShowModal = false;
        showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.audit") })
        this.getList();
        return;
      }
    },
    async getAuth() {
      const res = await getProposeAuth();
      this.isAuth = res.data;
    }
  },
  async mounted() {
    await this.getAuth();
    this.getList();
    this.onChangeTab();
  }
};
</script>
<style lang="less" scoped>
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
    &.view {
      color: @text-color;
    }
  }
}
.content-img-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.content-img {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.two-hidden {
  .multi-hidden(2);
}
.view-modal {
  &_content div {
    margin-bottom: @ec-gutter20;
    font-size: 18px;
  }
  .bold {
    font-weight: bold;
  }
}
</style>
