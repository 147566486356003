<template>
  <div>
    <a-breadcrumb class="ec-breadcrumb" :routes="breadcrumbList">
      <template slot="itemRender" slot-scope="{ route, routes }">
        <template v-if="route.href">
          <template v-if="routes.indexOf(route) !== routes.length - 1">
            <a-icon v-if="route.icon" :type="route.icon" />
            <router-link :to="route.href">{{ handleName(route) }}</router-link>
          </template>
          <template v-else>
            <template
              v-if="
                $route.fullPath !== route.href &&
                  $route.fullPath.includes(route.href)
              "
            >
              <router-link
                :to="route.href"
                :class="[
                  routes.indexOf(route) !== routes.length - 1
                    ? 'router-link'
                    : 'router-active'
                ]"
              >
                {{ handleName(route) }}
              </router-link>
              <span class="router-link">
                <span class="router-separator">/</span>
                {{ handleName(route) }}
                {{
                  $route.fullPath.endsWith("/add")
                    ? $t("button.add")
                    : $t("button.edit")
                }}
              </span>
            </template>
            <span v-else>{{ handleName(route) }}</span>
          </template>
        </template>
        <span v-else>
          <a-icon v-if="route.icon" :type="route.icon" />
          {{ handleName(route) }}
        </span>
      </template>
    </a-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { langMixin } from "@/mixins";

export default {
  mixins: [langMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      breadcrumbList: "home/breadcrumbList"
    })
  },
  methods: {
    handleName(route) {
      if (this.isEnglish && route.nameEn) {
        return route.nameEn;
      }
      return route.name;
    }
  }
};
</script>

<style lang="less" scoped>
.ec-breadcrumb {
  height: 46px;
  line-height: 46px;
}
.router-link {
  color: rgba(0, 0, 0, 0.45) !important;
}
.router-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.router-active {
  color: rgba(0, 0, 0, 0.65) !important;
  &:hover {
    color: #266fe8 !important;
  }
}
</style>
