<template>
  <div class="index">
    <!--#region 左侧 -->
    <div class="index-left">
      <!--#region 卡片 -->
      <div class="card activity" v-if="sleepLotteryActivityId">
        <router-link
          target="_blank"
          :to="`/activity/sleep-lottery?activityId=${sleepLotteryActivityId}`"
          class="old-users-lottery-entry"
        />
      </div>
      <div
        class="card"
        v-for="item in cardList"
        :key="item.id"
        v-show="!item.hide"
      >
        <div
          class="card-primary"
          :style="{ backgroundImage: `url(${item.bgUrl})` }"
        >
          <i :class="`iconfont ${item.icon} card-icon`"></i>
          <div class="card-title">{{ item.title }}</div>
          <a-button
            @click="handLink(item.routerName)"
            :style="{ backgroundColor: `${item.btnColor}`, color: '#fff' }"
            shape="round"
          >
            {{ item.btn }}
          </a-button>
        </div>
        <div class="card-item" v-for="i in item.fields" :key="i.id">
          <div class="card-item-count" :title="i.count">{{ i.count }}</div>
          <div class="card-item-title">
            <span :title="i.label">{{ i.label }}</span>
            <a-tooltip v-if="i.tips">
              <template slot="title">{{ item.tips }}</template>
              <a-icon
                type="exclamation-circle"
                class="card-item-tips"
                theme="filled"
              />
            </a-tooltip>
          </div>
        </div>
        <a
          class="card-setting"
          href="javascript: void(0);"
          v-if="item.id === 5"
          @click="industryVisible = true"
        >
          <a-icon type="setting" />
        </a>
      </div>
      <!--#endregion -->
      <!--#region EC 商城优选 -->
      <div class="mall" v-if="recommendList.length">
        <div class="mall-title">{{ $t("home.shop") }}</div>
        <div class="mall-item-wrap">
          <div class="mall-item" v-for="item in recommendList" :key="item.key">
            <div
              class="mall-item-image"
              :style="{ backgroundImage: `url(${item.pictureUrl})` }"
            ></div>
            <div class="mall-item-info">
              <div class="title">{{ item.productName }}</div>
              <div class="score">
                <span class="score-num">{{ item.salesPrice }}</span>
                {{ platformConf.moneyName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--#endregion -->
    </div>
    <!--#endregion -->
    <!--#region 右边 -->
    <div class="index-right">
      <!--#region 个人信息 -->
      <div class="person-info">
        <img :src="info.headImg" class="person-info-avatar" @click="onAvatar" />
        <div class="person-info-company">{{ info.currentCompany.name }}</div>
        <div class="person-info-member">
          {{ $t("home.member") }}
        </div>
      </div>
      <!--#endregion -->
      <!--#region 余额 -->
      <!-- <div class="person-money">
        <i class="iconfont icon-balance icon-size"></i>
        <span class="person-money-width">
          {{ $t("home.balance") }}
        </span>
        <span>
          <span class="person-money-text">{{ cashBalance }} </span>
          {{ $t("currency.price") }}
        </span>
      </div> -->
      <!--#endregion -->
      <!--#region 会员积分 -->
      <div class="person-money">
        <i class="iconfont icon-integral icon-size"></i>
        <span class="person-money-width">
          {{ $t("currency.growthPoints") }}
        </span>
        <span class="person-money-text">{{ growthPointsShown }}</span>
        <!-- <a-button type="link">
          {{ $t("home.use") }}
        </a-button> -->
      </div>
      <!--#endregion -->
      <!--#region 入职礼 -->
      <div class="person-money">
        <i class="iconfont icon-gift icon-size"></i>
        <span class="person-money-width">
          {{ $t("order.detail.joinGift") }}
        </span>
        <span class="person-money-text">
          {{ `0 ${$t("order.gift.unit")}` }}
        </span>
      </div>
      <!--#endregion -->
      <!--#region 专属客户经理 -->
      <div class="person-manager">
        <div class="person-manager-title">
          {{ $t("home.service") }}
        </div>
        <div class="person-manager-info" v-for="i in infoList" :key="i.icon">
          <i :class="`iconfont ${i.icon} icon-size`"></i>
          <span class="info-title">{{ i.value }}</span>
        </div>
      </div>
      <!--#endregion -->
      <!--#region HR记录 -->
      <div class="hr-record">
        <div class="hr-record-title">
          <span>{{ $t("home.records") }}</span>
          <i class="iconfont icon-message1 icon-size"></i>
        </div>
        <div class="hr-record-item" v-for="i in hrList" :key="i.id">
          <i class="iconfont icon-mess mess-size"></i>
          <div class="item">
            <div class="item-title">
              {{ i.userName }} {{ i.createBy }} {{ i.sourceName }}
            </div>
            <div class="item-date">{{ i.createTime }}</div>
          </div>
        </div>
      </div>
      <!--#endregion -->
    </div>
    <!--#endregion -->
    <IndustrySelectorModal
      :visible.sync="industryVisible"
      @smartSalary="onSmartSalary"
    />
    <SmartsalaryModal :visible.sync="smartsalaryVisible" />
  </div>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { pointsShowFormatter, productItemFormatter } from "@/utils/tools";
import { mapGetters, mapState } from "vuex";
import { clientProduct } from "@/pages/product/api";
import { getDic } from "@/api";
import { showMsg } from "@/utils";
import { customerService, getHRNotes, getStatistics } from "@/pages/home/api";
import BigNumber from "bignumber.js";
import IndustrySelectorModal from "./Industry/Modal.vue";
import SmartsalaryModal from "@/pages/home/dashboard/Smartsalary/Modal.vue";

export default {
  name: "Index",
  components: { SmartsalaryModal, IndustrySelectorModal },
  data() {
    return {
      infoList: [
        {
          icon: "icon-name",
          key: "trueName",
          value: ""
        },
        {
          icon: "icon-phone",
          key: "mobileNumber",
          value: ""
        },
        {
          icon: "icon-tell",
          key: "telNumber",
          value: ""
        },
        {
          icon: "icon-mail",
          key: "email",
          value: ""
        }
      ],
      hrList: [],
      recommendList: [],
      currencyList: [],
      surveyData: {
        completeQuantity: 0, // 已结束
        joinQuantity: 0, // 参与人数
        questionnaireQuantity: 0, // 收集问卷数
        startQuantity: 0 // 进行中
      }, // 调研数据
      benefitsData: {
        totalConsumption: 0, // 总消费
        userLoginQuantity: 0,
        userQuantity: 0, // 员工数
        weekActivity: 0, // 本周活跃
        weekConsumption: 0 // 本周消费
      }, // 福利数据
      backToneData: {
        newEntrustmentQuantity: 0, // 新增委托单量
        unreadReportQuantity: 0, // 未读报告
        waitEditEntrustmentQuantity: 0, // 待修改委托单
        waitStatementQuantity: 0 // 待查对账单
      }, // 背调数据
      encourageData: {
        approvedQuantity: 0, // 通过审核
        rewardQuantity: 0, // 发放奖励
        userLoginQuantity: 0,
        userQuantity: 0, // 员工数
        weekActivity: 0 // 本周活跃
      }, // 激励数据
      industryVisible: false,
      smartsalaryVisible: false
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
      info: state => state.user.info,
      growthPointsShown: state => state.user.growthPoints.shown,
      industryShowList: state => state.user.selectedIndustry
    }),
    ...mapGetters("user", ["cashBalance", "sleepLotteryActivityId"]),
    cardList() {
      const backToneCoinName =
        this.currencyList.find(it => it.dictCode === "back_tone_coin")
          ?.dictName || "";
      const benefitsCoinName =
        this.$store.state.user.platformConf.moneyName || "";
      const surveyCoinName =
        this.currencyList.find(it => it.dictCode === "survey_coin")?.dictName ||
        "";
      const backToneCoin = this.$store.getters["user/coinBalance"];
      const benefitsCoin = this.$store.getters["user/pointsBalance"];
      const surveyCoin = this.$store.getters["user/surveyBalance"];
      return [
        // 背景调查 - 背调币
        {
          id: 1,
          title: this.$t("home.card1.title"),
          bgUrl: require("@/assets/images/home_back.png"),
          btn: this.$t("home.card1.btn"),
          btnColor: "#266FE8",
          icon: "icon-back",
          routerName: "BackOrderAdd",
          tips: this.$t("home.card1.tips", { name: backToneCoinName }),
          fields: [
            // {
            //   label: backToneCoinName,
            //   count: backToneCoin,
            //   id: 1,
            //   tips: true
            // },
            // {
            //   label: this.$t("home.card1.field2"),
            //   count: this.backToneData.waitStatementQuantity || 0,
            //   id: 2
            // },
            {
              label: this.$t("home.card1.field3"),
              count: this.backToneData.newEntrustmentQuantity || 0,
              id: 3
            },
            {
              label: this.$t("home.card1.field4"),
              count: this.backToneData.unreadReportQuantity || 0,
              id: 4
            },
            {
              label: this.$t("home.card1.field5"),
              count: this.backToneData.waitEditEntrustmentQuantity || 0,
              id: 5
            }
          ]
        },
        // 员工福利平台 - 福币
        {
          id: 2,
          title: this.$t("home.card2.title"),
          bgUrl: require("@/assets/images/home_toon.png"),
          btn: this.$t("home.card2.btn"),
          btnColor: "#FE9833",
          icon: "icon-toon",
          routerName: "WelfarePlatIndex",
          tips: this.$t("home.card2.tips", { name: benefitsCoinName }),
          fields: [
            {
              label: benefitsCoinName,
              count: benefitsCoin,
              id: 1,
              tips: true
            },
            {
              label: this.$t("home.card2.field2"),
              count: this.benefitsData.userQuantity || 0,
              id: 2
            },
            {
              label: this.$t("home.card2.field3"),
              count: this.benefitsData.weekActivity || 0,
              id: 3
            },
            {
              label: this.$t("home.card2.field4"),
              count: pointsShowFormatter(
                this.benefitsData.weekConsumption || 0
              ),
              id: 4
            },
            {
              label: this.$t("home.card2.field5"),
              count: pointsShowFormatter(
                this.benefitsData.totalConsumption || 0
              ),
              id: 5
            }
          ]
        },
        // 员工激励平台-小妙计 - 福币
        {
          id: 3,
          title: this.$t("home.card3.title"),
          bgUrl: require("@/assets/images/home_drive.png"),
          btn: this.$t("home.card3.btn"),
          btnColor: "#49B073",
          icon: "icon-drive",
          tips: this.$t("home.card3.tips", { name: benefitsCoinName }),
          routerName: "auditList",
          fields: [
            {
              label: benefitsCoinName,
              count: benefitsCoin,
              id: 1,
              tips: true
            },
            {
              label: this.$t("home.card3.field2"),
              count: this.encourageData.userQuantity || 0,
              id: 2
            },
            {
              label: this.$t("home.card3.field3"),
              count: this.encourageData.weekActivity || 0,
              id: 3
            },
            {
              label: this.$t("home.card3.field4"),
              count: this.encourageData.approvedQuantity || 0,
              id: 4
            },
            {
              label: this.$t("home.card3.field5"),
              count: pointsShowFormatter(
                this.encourageData.rewardQuantity || 0
              ),
              id: 5
            }
          ]
        },
        // 员工敬业度/满意度调研 - 调研币
        {
          hide: true,
          id: 4,
          title: this.$t("home.card4.title"),
          bgUrl: require("@/assets/images/home_satisfy.png"),
          btn: this.$t("home.card4.btn"),
          btnColor: "#AC9BE5",
          icon: "icon-satisfy",
          tips: this.$t("home.card4.tips", { name: surveyCoinName }),
          fields: [
            {
              label: surveyCoinName,
              count: surveyCoin,
              id: 1,
              tips: true
            },
            {
              label: this.$t("home.card4.field2"),
              count: this.surveyData.startQuantity || 0,
              id: 2
            },
            {
              label: this.$t("home.card4.field3"),
              count: this.surveyData.completeQuantity || 0,
              id: 3
            },
            {
              label: this.$t("home.card4.field4"),
              count: this.surveyData.questionnaireQuantity || 0,
              id: 4
            },
            {
              label: this.$t("home.card4.field5"),
              count: this.surveyData.joinQuantity || 0,
              id: 5
            }
          ]
        },
        // 行业薪酬
        {
          id: 5,
          title: this.$t("home.card5.title"),
          bgUrl: require("@/assets/images/homeProduct_bg05.png"),
          btn: this.$t("home.card5.btn"),
          btnColor: "#72BDD2",
          icon: "icon-industry",
          tips: "",
          routerName: "smartSalary",
          fields: this.industryShowList.map(it => ({
            label: it.label,
            count: it.value,
            id: it.name,
            tips: false
          }))
        }
      ];
    }
  },
  methods: {
    // 跳转链接
    handLink(name) {
      if (name === "smartSalary") {
        this.onSmartSalary(true);
        return;
      }
      this.$router.push({ name });
    },
    // 头像点击事件
    onAvatar() {
      this.$router.push("/member-center");
    },
    // 获取推荐商城物品
    async getRecommend() {
      const res = await clientProduct({
        pageNum: 1,
        pageSize: 10,
        isRecommend: true
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.recommendList = res.data.records.map(it => productItemFormatter(it));
    },
    // 获取币种字典
    async getCurrency() {
      const res = await getDic({ code: "currency" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.currencyList = [];
        return;
      }
      this.currencyList = res.data || [];
    },
    async getDataList() {
      const res = await getStatistics();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.backToneData = res.data?.backToneData;
      this.benefitsData = res.data?.benefitsData;
      this.benefitsData.weekActivity =
        BigNumber(this.benefitsData.weekActivity)
          .times(100)
          .toFixed(2) + "%";
      this.encourageData = res.data?.encourageData;
      this.encourageData.weekActivity =
        BigNumber(this.encourageData.weekActivity)
          .times(100)
          .toFixed(2) + "%";
      this.surveyData = res.data?.surveyData;
    },
    async getHRNote() {
      const res = await getHRNotes({ num: 5 });
      this.hrList = res.data || [];
    },
    async getCustomerService() {
      const res = await customerService();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const data = res.data || {};
      const ls = this.infoList.slice();
      for (const it of ls) {
        it.value = data[it.key];
      }
      this.infoList = ls;
    },
    onSmartSalary(visible) {
      this.smartsalaryVisible = visible;
    }
  },
  mounted() {
    this.getDataList();
    this.getRecommend();
    this.getCurrency();
    this.getHRNote();
    this.getCustomerService();
    this.$store.dispatch("user/updateOrderStatus", {
      activityId: this.sleepLotteryActivityId
    });
  }
};
</script>

<style lang="less" scoped>
@cardH: 164px;
@leftW: 72%;
@rightW: 28%;

@keyframes dropInDown {
  from {
    height: 0;
  }
  to {
    height: 200px;
  }
}

.index {
  background: #fff;
  display: flex;
  color: @font-color;
  overflow: auto;
  &-left {
    padding: 0 20px 0 0;
    width: @leftW;
    .card {
      background: #ffffff;
      box-shadow: 0px 4px 4px 1px rgba(15, 113, 222, 0.06);
      border: 1px solid #e9ebfb;
      box-sizing: content-box;
      // height: @cardH;
      display: flex;
      align-items: center;
      position: relative;

      &:not(&:first-child) {
        margin-top: 20px;
      }
      &-item {
        width: 16.67%;
        text-align: center;
        &-title {
          font-size: @font-size-large;
          color: #757e8c;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-count {
          font-size: 28px;
          font-weight: bold;
        }
        &-tips {
          color: @warning-color;
          margin-left: 8px;
          font-size: 16px;
          cursor: pointer;
        }
      }
      &-primary {
        width: 16.67%;
        min-width: 150px;
        background: url("~@/assets/images/home_back.png") no-repeat center;
        height: 100%;
        background-size: cover;
        text-align: center;
        padding: 12px;
        .card-icon {
          font-size: 36px;
        }
        .card-title {
          font-size: @font-size-large;
          margin-bottom: 12px;
        }
      }
      &-setting {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 10px;
        font-size: 18px;
      }

      &.activity {
        height: 200px;
        animation-fill-mode: both;
        animation-name: dropInDown;
        animation-duration: 900ms;
        animation-timing-function: ease-in;
        .old-users-lottery-entry {
          display: block;
          width: 100%;
          height: 200px;
          background: url("~@/assets/images/activity/sleep-lottery/lottery-banner.png") no-repeat center / cover;
        }
      }
    }
    .mall {
      &-title {
        font-size: 18px;
        margin: 30px 0;
      }
      &-item-wrap {
        display: flex;
        flex: 0 0 20%;
        flex-wrap: wrap;
        .mall-item {
          width: 234px;

          &:not(&:last-child) {
            margin-right: 30px;
          }
          &-image {
            height: 208px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #dddddd;
            border-radius: 8px;
          }
          &-info {
            .title {
              font-size: @font-size-large;
              .single-hidden();
            }
            .score {
              text-align: center;
              &-num {
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
  .icon-size {
    font-size: 24px;
  }

  &-right {
    border-left: 1px solid #e9ebfb;
    flex-shrink: 0;
    width: @rightW;
    border: 1px solid #e9ebfb;
    padding: 36px 20px;
    .person {
      &-info {
        margin-bottom: 24px;
        text-align: center;
        &-avatar {
          margin: 0 auto;
          width: 92px;
          height: 92px;
          cursor: pointer;
        }
        &-company {
          font-size: 18px;
          margin: 12px 0 16px 0;
        }
        &-member {
          margin: 0 auto;
          width: 82px;
          height: 32px;
          line-height: 27px;
          background: url("~@/assets/images/home_member.png") center no-repeat;
          background-size: contain;
          color: #fff;
        }
      }
      &-money {
        display: flex;
        align-items: center;
        &-text {
          font-size: @font-size-large;
          font-weight: bold;
          flex: 1 0;
        }
        &-width {
          width: 35%;
          margin-left: 10px;
          white-space: nowrap;
        }
      }
      &-manager {
        margin-top: 40px;
        margin-bottom: 40px;
        width: 100%;
        background: #f9faff;
        box-shadow: 0px 10px 12px 1px rgba(230, 237, 246, 0.4);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #e9ebfb;
        padding: 20px 44px 0 30px;
        &-title {
          text-align: center;
          font-size: 18px;
          margin-bottom: 24px;
        }
        &-info {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
          .info-title {
            word-break: break-all;
          }
          .icon-size {
            font-size: 18px;
            margin-right: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
    .hr-record {
      &-title {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }
      &-item {
        &:last-child {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.5) 0%,
            #ffffff 93%
          );
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
        display: flex;
        align-items: center;
        .mess-size {
          font-size: 40px;
          flex-shrink: 0;
          margin-right: 16px;
        }
        .item {
          overflow: hidden;
          &-title {
            .single-hidden();
          }
          &-date {
            color: #a6aab2;
            font-size: @font-size-small;
          }
        }
      }
    }
  }
}
</style>
