<template>
  <a-modal
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="industry-selector-modal"
    :maskClosable="false"
    :title="$t('home.card5.title')"
    width="712px"
    destroyOnClose
    :closable="false"
  >
    <Index
      ref="indexRef"
      @smartSalary="onSmartSalary"
      @cancel="onCancel"
    />
  </a-modal>
</template>

<script>
import Index from "./Index.vue";
import { showMsg } from "@/utils";
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins";

export default {
  name: "IndustrySelectorModal",
  components: { Index },
  mixins: [langMixin],
  props: {
    visible: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onOk() {
      if (this.$refs.indexRef.selected.length < 1) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: "至少选择一个指标！",
        });
        return;
      }
      this.$store.commit("user/setSelectedIndustry", {
        selected: this.$refs.indexRef.selected,
        lang: this.lang,
      });
      this.onCancel();
    },
    onCancel() {
      this.$emit("update:visible", false);
    },
    onSmartSalary() {
      this.$emit("smartSalary", true);
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped></style>
