<template>
  <div class="ec-demo">
    <i class="iconfont icon-drive"></i>
    <a-table
        :columns="columns"
        :data-source="list"
        bordered
        class="table-demo"
        :pagination="pagination"
        :scroll="{ y: tableFullScrollY }"
        :customRow="onRow"
        :loading="loading"
        @change="onPage"
        :style="{ minHeight: tableFullScrollY }"
    >
      <template slot="name" slot-scope="text">
        <a>{{ text }}</a>
      </template>
      <template slot="title">
        表格
      </template>
    </a-table>
  </div>
</template>

<script>
import { tablePagination } from "@/mixins";
import { setTableHeight } from "@/utils";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Cash Assets",
    className: "column-money",
    dataIndex: "money",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
];

const testData = [...new Array(100).keys()].map((i) => (
    {
      key: "id-" + i,
      name: "John " + i,
      money: i,
      address: "New York No. " + i + "  Lake Park",
    }
));

export default {
  mixins: [tablePagination],
  data() {
    return {
      list: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
      },
      // 表格行事件实现
      onRow(record, index) {
        return {
          on: {
            click: () => {
              console.log(record, index);
            },
          },
        };
      },
    };
  },
  computed: {
    tableFullScrollY() {
      if (!testData.length) return this.$store.state.tableFullScrollY + 56;
      return this.$store.state.tableFullScrollY;
    },
  },
  created() {
    // 请求第一页
    this.getList();
  },
  mounted() {
    // 当数据较少时也能铺满页面
    setTableHeight(".table-demo", this.tableFullScrollY);
  },
  methods: {
    setPagination(values) {
      this.pagination = { ...this.pagination, ...values };
    },
    onPage({ current, pageSize }) {
      this.setPagination({ current, pageSize });
      this.getList();
    },
    getList() {
      // 获取当前页数据
      this.loading = true;
      setTimeout(() => {
        const { pageSize, current } = this.pagination;
        const result = testData.slice(
            pageSize * (
                current - 1
            ),
            pageSize * current
        );
        this.list = result;
        this.setPagination({ total: testData.length });
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.ec-demo {
  height: 100%;
}

.table-demo {
  height: 100%;
}
</style>
