import api from "@/utils/fetch";

// 查询用户建议-分页
export function getProposePage (params) {
  return api.post('/user/propose/page', params)
}

// 审核通过
export function passPropose (params) {
  return api.post('/user/propose/approval/pass', params)
}

// 审核拒绝
export function rejectPropose (params) {
  return api.post('/user/propose/approval/reject', params)
}

// 是否具有审批权限
export function getProposeAuth (params) {
  return api.get('/user/propose/approval/auth', params)
}

