<template functional>
  <a-sub-menu class="home-sider-menu-item sub" :key="props.menu.permissionId">
    <span slot="title">
      <a-icon :type="props.menu.icon" v-if="props.menu.icon" />
      <span class="title-text sub">{{ parent.$t(props.menu.name) }}</span>
    </span>

    <template v-for="it in props.menu.children">
      <sub-menu
        v-if="it.children && it.children.length"
        :key="it.permissionId"
        :menu="it"
      />
      <a-menu-item
        :key="it.permissionId"
        class="home-sider-menu-item"
        v-if="!(it.children && it.children.length) && !it.hideFlag"
      >
        <a-icon :type="it.icon" v-if="it.icon" />
        <span class="title-text">{{ parent.$t(it.name) }}</span>
      </a-menu-item>
    </template>
  </a-sub-menu>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="less" scoped></style>
