<template>
  <a-layout-header class="ec-header">
    <div class="ec-header-left">
      <h2 class="ec-header-title">{{ title }}</h2>
    </div>
    <div class="ec-header-action">
      <a-button class="version" @click="onReturnVersion">{{$t('user.backVersion')}}</a-button>
      <img
          :src="info.headImg"
          class="company-logo"
          @click="onAvatar"
      />
      <div class="company-name">{{ name }}</div>
      <a-tooltip placement="bottom">
        <template slot="title">
          <span>{{$t('menu.message.index')}}</span>
        </template>
        <i class="iconfont icon-message1" @click="handleMessage" />
      </a-tooltip>
      <a-tooltip placement="bottom">
        <template slot="title">
          <span>{{$t('user.signOut')}}</span>
        </template>
        <i class="iconfont icon-exit" @click="exit" />
      </a-tooltip>
    </div>
  </a-layout-header>
</template>

<script>
import { clearCookies, OLD_WEBSITE } from "@/config";

export default {
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$route.meta?.title;
    },
    info() {
      return this.$store.state.user.info;
    },
    name() {
      let name = this.info.name;
      if (this.info.currentCompany) {
        name = `${name}-${this.info.currentCompany.name}`;
      }
      return name;
    }
  },
  methods: {
    exit() {
      this.$confirm({
        title: this.$t("showMsg.confirm.exitTitle"),
        content: this.$t("showMsg.confirm.exit"),
        onOk: () => {
          clearCookies();
          this.$store.dispatch("user/clearUserInfo");
          this.$store.dispatch("home/clearHomeInfo");
          this.$router.replace("/user/sign-in");
        },
        onCancel: () => {}
      });
    },
    handleMessage() {
      this.$router.push("/message/list");
    },
    onAvatar() {
      this.$router.push("/member-center");
    },
    onReturnVersion () {
      window.open(OLD_WEBSITE, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.ec-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: #fff;
  position: relative;
  border-bottom: 1px solid @menu-color;

  &-left {
    display: flex;
    align-items: center;
    padding-left: 35px;

    .ec-header-title {
      font-size: 20px;
      font-weight: 500;
      color: #343862;
      margin: 0;
    }
  }

  &-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 40px;

    .version {
      margin-right: 16px;
    }

    i {
      font-size: 20px;
      margin-left: @ec-gutter20;
      .pointer();
    }

    .icon-exit {
      font-size: 24px;
    }

    .company-logo {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 4px;
      cursor: pointer;
      border: 1px solid #dddddd;
    }

    .company-name {
      color: #343862;
    }
  }
}
</style>
